<template>
  <div>
    <login-details
      :user-type="'Teacher'"
    >
    </login-details>
  </div>
</template>

<script>

// Components
import LoginDetails from '../components/loginDetails/loginDetails.vue';

// Icon mdiInformationOutline

export default {
  components: {
    LoginDetails,
  },
  setup() {
    return {
      LoginDetails,
    }
  },
}
</script>

<style>
.v-item-group.theme--dark.v-slide-group.v-tabs-bar.v-tabs-bar--is-mobile.v-tabs-bar--show-arrows.primary--text {
  background: transparent
}
</style>
