import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.websitesToShow.length > 0)?_c(VTabs,{staticClass:"user-tabs",attrs:{"show-arrows":""},model:{value:(_vm.platformTab),callback:function ($$v) {_vm.platformTab=$$v},expression:"platformTab"}},_vm._l((_vm.websitesToShow),function(website){return _c(VTab,{key:website.easyId},[_c('span',[_vm._v(_vm._s(website.name))])])}),1):_vm._e(),(_vm.school.role !== 'Leerkracht' && _vm.websitesToShow.length > 0)?_c(VAlert,{staticClass:"mt-5",attrs:{"color":"accent","dark":"","icon":_vm.icons.mdiInformationOutline}},[_vm._v(" Inloggegevens bewerken? Doorklikken naar "+_vm._s((_vm.userType === "Pupil" ? "leerling" : "leerkracht"))+", tabje inloggegevens, dan klikken op het potloodje. ")]):_vm._e(),(_vm.websitesToShow.length > 0)?_c(VTabsItems,{staticClass:"mt-5 pa-1",staticStyle:{"background":"transparent"},attrs:{"id":"class-tabs-content"},model:{value:(_vm.platformTab),callback:function ($$v) {_vm.platformTab=$$v},expression:"platformTab"}},_vm._l((_vm.websitesToShow),function(website){return _c(VTabItem,{key:website.easyId},[_c('platform-tab',{attrs:{"website":website,"user-type":_vm.userType}})],1)}),1):_c(VCard,[_c(VCardText,[_vm._v("Jammer genoeg zijn er momenteel geen platforms beschikbaar voor de "+_vm._s((_vm.userType === "Pupil" ? "leerling" : "leerkracht"))+"en. Probeer het later opnieuw.")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }