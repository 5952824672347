<template>
  <div>
    <!-- table -->

    <v-card rounded>
      <v-card-title>
        Inloggegevens voor {{ website.name }}
      </v-card-title>
      <v-data-table
        v-model="selectedRows"
        :headers="tableHeaders"
        :items="loginDetails"
        :loading="loading"
        loading-text="Inloggegevens aan het laden..."
        no-data-text="Geen inloggegevens gevonden"
      >
        <!-- Class name -->
        <template #[`item.account`]="{ item }">
          <router-link
            :to="{ name: userClickthroughLink, params: { id: item.account.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <span><b>{{ item.account.firstName }} {{ item.account.lastName }}</b></span>
          </router-link>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="removeLoginDetail(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Verwijderen</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from '@axios'
import { computed, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

// Import icons
import {
  mdiDeleteOutline, mdiDotsVertical, mdiFileDocumentOutline, mdiInformationOutline,
} from '@mdi/js'

function toast(status, title, text, duration = 3000) {
  Swal.fire({
    position: 'top-end',
    icon: status,
    title,
    text,
    showConfirmButton: false,
    timer: duration,
    toast: true,
  })
}

// import { avatarText } from '@core/utils/filter'
export default {
  props: {
    website: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Useful stuff
    const loading = ref(true)
    const school = JSON.parse(localStorage.getItem('school'))

    // Table properties
    const selectedRows = ref([])

    const websites = ref([])
    axios.get('/website')
      .then(res => {
        websites.value = res.data.websites
      })

    const fields = ref([])
    const tableHeaders = ref([{
      text: 'Account',
      value: 'account',
      sortable: false,
    }])

    axios.get(`/website/${props.website.id}/fields`)
      .then(res => {
        fields.value = res.data.fieldNames
        tableHeaders.value.push(...res.data.fieldNames.map(field => ({
          text: field.charAt(0).toUpperCase() + field.slice(1),
          value: field,
          sortable: false,
        })))
        tableHeaders.value.push({
          text: 'Acties',
          value: 'actions',
          sortable: false,
        })
      })

    const loginDetails = ref([])
    function fetchData() {
      axios.get(`/school/${school.id}/logindetails?websiteId=${props.website.id}`)
        .then(res => {
          loginDetails.value = res.data.loginDetails.map(loginDetail => {
            loginDetail.credentials.forEach(credential => {
            // eslint-disable-next-line no-param-reassign
              loginDetail[credential.fieldName] = credential.fieldValue
            })

            return loginDetail
          })
          loading.value = false
        })
    }
    fetchData()

    const userClickthroughLink = computed(() => {
      if (props.userType === 'Pupil') {
        return 'users-pupils-view'
      }

      return 'users-teachers-view'
    })

    // Functions
    async function removeLoginDetail(ld) {
      Swal.fire({
        title: 'Opgelet!',
        text: `Ben je zeker dat je de inloggegevens van ${ld.account.firstName} ${ld.account.lastName} voor ${props.website.name} wilt verwijderen?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, verwijderen',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await axios.delete(`/account/${ld.account.id}/logindetails/${ld.id}`)
          if (response.data.success) {
            fetchData()

            return toast('success', 'Gelukt!', 'De inloggegevens zijn verwijderd')
          }

          return toast('error', 'Probeer het later opnieuw...', 'Er is iets misgelopen bij het verwijderen van de inloggegevens.')
        }

        return false
      })
    }

    return {
      // Data
      loading,
      school,
      selectedRows,
      websites,
      fields,
      tableHeaders,
      loginDetails,
      userClickthroughLink,

      // Methods
      removeLoginDetail,

      // Icons
      icons: {
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>
