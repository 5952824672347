<template>
  <div>
    <v-tabs
      v-if="websitesToShow.length > 0"
      v-model="platformTab"
      show-arrows
      class="user-tabs"
    >
      <v-tab
        v-for="website in websitesToShow"
        :key="website.easyId"
      >
        <span>{{ website.name }}</span>
      </v-tab>
    </v-tabs>

    <v-alert
      v-if="school.role !== 'Leerkracht' && websitesToShow.length > 0"
      color="accent"
      dark
      :icon="icons.mdiInformationOutline"
      class="mt-5"
    >
      Inloggegevens bewerken? Doorklikken naar {{ (userType === "Pupil" ? "leerling" : "leerkracht") }}, tabje inloggegevens, dan klikken op het potloodje.
    </v-alert>

    <v-tabs-items
      v-if="websitesToShow.length > 0"
      id="class-tabs-content"
      v-model="platformTab"
      class="mt-5 pa-1"
      style="background:transparent;"
    >
      <v-tab-item
        v-for="website in websitesToShow"
        :key="website.easyId"
      >
        <platform-tab
          :website="website"
          :user-type="userType"
        ></platform-tab>
      </v-tab-item>
    </v-tabs-items>
    <v-card v-else>
      <v-card-text>Jammer genoeg zijn er momenteel geen platforms beschikbaar voor de {{ (userType === "Pupil" ? "leerling" : "leerkracht") }}en. Probeer het later opnieuw.</v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from '@axios'
import { mdiInformationOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import PlatformTab from './platformTab/platformTab.vue'

// Components

// Icon mdiInformationOutline

export default {
  components: {
    PlatformTab,
  },
  props: {
    userType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Tabs
    const platformTab = ref(null)

    const websites = ref([])

    // a computed ref

    const websitesToShow = computed(() => {
      if (props.userType === 'Pupil') {
        return websites.value.filter(website => website.canBeUsedByStudents)
      }

      return websites.value.filter(website => website.canBeUsedByTeachers)
    })

    axios.get('/website')
      .then(res => {
        websites.value = res.data.websites
      })

    const school = JSON.parse(localStorage.getItem('school'))

    return {
      school,
      platformTab,
      websites,
      icons: {
        mdiInformationOutline,
      },
      websitesToShow,
    }
  },
}
</script>

<style>
.v-item-group.theme--dark.v-slide-group.v-tabs-bar.v-tabs-bar--is-mobile.v-tabs-bar--show-arrows.primary--text {
  background: transparent
}
</style>
